<template>
    <v-navigation-drawer width="1280" style="height: 100%" fixed right v-model="show" app temporary>
    <v-col class="px-0">
      <v-row class="">
        <v-col align="right" class="pb-0 mr-3">
          <v-icon @click="onCloseHandler" class="primaryColorOnHover xClass">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mx-8">
        <v-col class="pl-9" cols="12" xs="12" sm="7" md="6" lg="6" xl="6"
          :align="mobileBreakpoint == true ? 'left' : ''" :class="mobileBreakpoint == true ? 'pl-0' : 'pl-6'">
          <HeaderTitle :title="$t('newDemandRegistration')" />
        </v-col>
        <v-col cols="12" xs="12" sm="5" md="6" lg="6" xl="6" align="right"
          :class="mobileBreakpoint == true ? 'pr-8' : ''">
          <v-btn class="ma-1 textDecorationBold btn ma-0" @click="onSave" color="primary" outlined max-width="126"
            max-height="38" min-width="126" min-height="38">
            {{ $t("save") }}</v-btn>
          <v-btn class="btn-custom ml-4 mr-1 unsetCapitalize" @click="onSend" color="error" max-width="126"
            max-height="38" min-width="126" min-height="38">
            {{ $t("send") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col class="pl-9">
          <v-tabs v-model="activeTab" class="pl-8">
            <v-tabs-slider style="height: 0px"> </v-tabs-slider>
            <v-tab class="unsetCapitalize textDecorationBold " background-color="#E52628">
              {{ $t("demand") }}
            </v-tab>
            <v-tab class=" unsetCapitalize textDecorationBold ">
              {{ $t("recommendation") }}
            </v-tab>
            <v-tab class="unsetCapitalize textDecorationBold ">
              {{ $t("assignment") }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-divider />

      <v-row class="">
        <v-col class="">
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <DemandCall :updateAttribute="updateAttribute" :attributes="data.demandData" />
            </v-tab-item>
            <v-tab-item>
              <AssignmentCall />
            </v-tab-item>
            <v-tab-item>
              <RecommendationCall />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>
  
<script>
/* 
  Name:createCall
  Description: Component to create a call
  API Property   
    closeHandler: Function on close
*/
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import DemandCall from "@/components/Secretary/CreateCall/DemandCall.vue";
import AssignmentCall from "@/components/Secretary/CreateCall/AssignmentCall.vue";
import RecommendationCall from "@/components/Secretary/CreateCall/RecommendationCall.vue";

export default {
  name: "createCall",
  components: {
    HeaderTitle,
    DemandCall,
    AssignmentCall,
    RecommendationCall,
  },
  props: {
    closeHandler: Function,
  },
  data() {
    return {
      show: true,
      activeTab: 0,
      tabs: [
        {
          name: "demand",
          path: "/createCall/demandCall",
        },
        {
          name: "recommendation",
          path: "/createCall/recommendationCall",
        },
        {
          name: "assignment",
          path: "/createCall/assignmentCall",
        },
      ],

      data: {
        demandData: {
          quickPartnerSearch: null,
          propertyCode: null,
          nextFollowUpFormatted: null,
          nextFollowUpStart: null,
          Task: null,
          Category: null,
          subCategory: null,
          priceFrom: null,
          priceTo: null,
          priceSqftFrom: null,
          priceSqftTo: null,
          floorMin: null,
          floorMax: null,
          constructionTo: null,
          constructionFrom: null,
          renovationTo: null,
          renovationFrom: null,
          status: [],
          checkbox: [],
          selectedMu: 0,
          importance: null,
          description: null,
          bedrooms: 0,
        },
      },
    };
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
  },
  methods: {
    onSave() {
    },

    onSend() {
    },

    onCloseHandler() {
      this.closeHandler();
    },

    updateAttribute(kind, attribute, value) {
      this.data[kind][attribute] = value;
    },
  },
};
</script>

<style scoped>
.btn {
  text-transform: unset !important;
  color: #263573;
  background-color: white;
  font-weight: 500 !important;
}

.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  background-color: white;
}

.v-btn-toggle--group>.v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle>.v-btn.v-btn {
  /* border-radius: 5px !important; */
  border-width: thin !important;
  background-color: #f8f8f8 !important;
}

#active-button {
  background-color: #263573 !important;
}

.theme--light.v-btn-toggle.v-btn-toggle--group {
  background: #f8f8f8;
  color: #263573;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #f8f8f8;
  color: #263573;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 36px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

.v-btn-toggle--group>.v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle>.v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.headerRightArea {
  flex: 0 0 40%;
  max-width: 40%;
  text-align: -webkit-right;
}

.headerRightSubArea {
  justify-content: flex-end;
}

.rightTagsArea {
  flex-wrap: nowrap;
}

.headerArea {
  flex-wrap: nowrap;
}

.v-tab--active {
  color: #E52628
}

@media only screen and (max-width: 500px) {
  .rightTagsArea {
    flex-wrap: wrap;
  }

  .masterEditSliderContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 959px) {
  .vertDivider {
    display: none !important;
  }
}

@media only screen and (max-width: 700px) {
  .headerArea {
    flex-wrap: wrap;
  }

  .headerRightArea {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
    justify-content: flex-start;
    text-align: -webkit-left;
  }

  .headerRightSubArea {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .headerRightArea {
    margin-bottom: 0px;
  }

  .headerRightSubArea {
    justify-content: flex-end;
  }
}
</style>