<template>
    <v-col>
      <v-row class="align-center justify-space-between topRow">
        <v-col class="text-no-wrap pb-0 pr-0">
          <HeaderTitle
            :title="$t('assignment')"
            fontSizeTitle="24px"
            fontFamilyTitle="CenturyGothicBold"
            fontSizeSub="16px"
            fontFamilySub="CenturyGothicBold"
          />
        </v-col>
      </v-row>
     
    </v-col>
  </template>
  
  <script>
  import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
  
  export default {
    name: "AssignmentCall",
    components: {
        HeaderTitle,
    },

  };
  </script>
  